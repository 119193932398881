<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="12">
            <el-button-group>
              <el-button @click="searchGo" type="primary">搜索</el-button>
              <el-button @click="searchReset">重置</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column width="80" prop="id" label="编号"></el-table-column>
        <el-table-column prop="user.phone" label="用户手机"></el-table-column>
        <el-table-column prop="fee" label="提现金额"></el-table-column>
        <el-table-column prop="real_fee" label="实际到账"></el-table-column>
        <el-table-column prop="rate_fee" label="手续费"></el-table-column>
        <el-table-column prop="get_info_log" label="提现方式">
          <template #default="s">
            <div v-if="parseObj(s.row.get_info_log).id > 0">
              <div>{{parseObj(s.row.get_info_log).bank_name}}</div>
              <div>姓名：{{parseObj(s.row.get_info_log).name}}</div>
              <div>账号：{{parseObj(s.row.get_info_log).account}}</div>
            </div>
            <div v-else>余额提现</div>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="申请时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="fail_log" label="异常信息" show-overflow-tooltip></el-table-column>
        <el-table-column fixed="right" width="300" align="center" label="审核状态">
          <template #default="s">
            <el-button @click="fail(s.row.id)" size="mini" type="danger">无效</el-button>
            <el-button  @click="payCash(s.row)" size="mini" type="success">
              {{isWechatChannel(parseObj(s.row.get_info_log).type) ? '打款':'通过'}}
            </el-button>
            <el-button v-if="isWechatChannel(parseObj(s.row.get_info_log).type)" @click="payCheck(s.row.id)" size="mini" type="success">检测</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    isWechatChannel(target){
      return [1,4,6].includes(target)
    },
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
      }
      this.searchGo()
    },
    fail(id){
      this.$u.api.user.cashFail({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    payCash({id,get_info_log}){
      this.$u.api.user.cashPay({id}).then(()=>{
        let str = "操作成功";
        if (this.isWechatChannel(this.parseObj(get_info_log).type)){
          str += ":如已启用商家转账到零钱，需点击检测查看打款状态";
        }
        this.$message.success(str);
        this.load();
      })
    },
    payCheck(id){
      this.$u.api.user.cashCheck({id}).then((res)=>{
        this.$message.success(res);
        this.load();
      })
    },
    parseObj(log){
      if (!log)return {};
      return JSON.parse(log);
    },
    load() {
      this.$u.api.user.cashSearch({
        page:this.page,
        status:"wait",
        phone:this.searchForm.phone
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>